import "../../assets/css/Login.css";
import React, { useEffect, useState } from "react";
import "../../assets/fontawesome/css/all.min.css";
import "../../assets/css/background.css";
import "../../assets/css/backgroundHeader.css";
import "../../assets/css/backgroundButton.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import "../../assets/css/font-text.css";
import { userServices } from "../../services/apiService/userServices";
import { userDataLocal } from "../../services/localService/localService";
import { setUserData } from "../../Redux/Action/userAction";
import { useDispatch } from "react-redux";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import { qrServices } from "../../services/apiService/qrservice";
import { setAuthorizationQR } from "../../services/apiService/configQrURL";
import { receiptServices } from "../../services/apiService/receiptServices";
import { format } from "date-fns";
import uuid from "react-uuid";
import SubmitReceipt from "../../component/SubmitReceipt/SubmitReceipt";
import md5 from "js-md5";
import queryString from "query-string";

const KEY = `sf63b4s3df5b4s6s68sr4sr4b6s8bsrb8s64vaev4ae63f8b4sf54b+635sdf4bs563s5dfb4`;

function LoginQR() {
  const navigation = useNavigate();
  let appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  let dispatch = useDispatch();
  const [checkAgree1, setCheckAgree1] = useState(false);
  const [checkAgree2, setCheckAgree2] = useState(false);
  const [notify, setNotify] = useState("");
  const [radio, setRadio] = useState("smartphone");

  const handleAgree = (e) => {
    setNotify("");
    setCheckAgree1(!checkAgree1);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [triggerSubmitReceipt, setTriggerSubmitReceipt] = useState(false);

  const onSubmit = (data) => {
    localStorage.setItem("PHONE_TYPE", radio);
    localStorage.setItem("STAFF_NAME", data.name);
    setLoading(true);
    setUserName(data.name);
    const phoneFormat = {
      password: data.password,
      login: data.name,
    };
    console.log(phoneFormat);
    qrServices
      .postUserLogin(phoneFormat)
      .then((res) => {
        console.log(res);
        setAuthorizationQR(res.token);
        localStorage.setItem("TOKEN_QR", JSON.stringify(res.token));
        navigation(`/${appCode}/doiqua-inputcode`);
        let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
        // submitReceipt(gcsResult);
        // setTriggerSubmitReceipt(true);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const [show, setShowMore] = useState(false);
  const showMore = () => {
    setShowMore(!show);
  };

  const handleNotify = () => {
    setNotify("Vui lòng chọn tôi đồng ý để tiếp tục");
  };
  const handleRadio = () => {
    setRadio("basic");
  };
  const handleRadio2 = () => {
    setRadio("smartphone");
  };
  const location = useLocation();
  console.log(location);
  useEffect(() => {
    console.log(appCode);
    if (
      appCode === "coopmart" ||
      appCode === "bigc" ||
      appCode === "aeon" ||
      appCode === "lotte" ||
      appCode === "emart"
    ) {
    } else {
      console.log(appCode);

      navigation("/notfound");
    }
    // const a = queryString.parse(location?.search);
    // const employy_name = a.employee_name;
    // const employee_code = a.employee_code;
    // const hash_webview = a.hash;
    // const hashFE = md5(`${employy_name}|${employee_code}|${KEY}`);
    // console.log(hashFE);
    // // 2607f6629fad29dcb988309f2a607437
    // if (hashFE === hash_webview) {
    //   console.log("hello");
    //   localStorage.setItem("EMPLOYEE_NAME", employy_name);
    //   navigation(`/${appCode}/doiqua-inputcode`);
    // } else {
    //   navigation("/notfound");
    // }
  }, []);
  return (
    <>
      {triggerSubmitReceipt ? (
        <SubmitReceipt
          typePhone={radio}
          userName={userName}
          trigger={triggerSubmitReceipt}
        />
      ) : null}
      <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <div className="icon-back mt-[7%] py-[36px] h-8 flex items-center opacity-100 max-w-full w-full z-50"></div>
        <header className="p-0 w-52 h-9 leading-9 text-2xl not-italic ">
          ĐĂNG NHẬP
        </header>
        <div className="container__login-item p-[16px_0_68px_0] text-[#fff] text-base font-light w-[87%]">
          Nhập tên và mật khẩu của bạn để đăng nhập vào App
        </div>
        <div className="container__login-form">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__login">
              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0 bg-[#fff] border-[#98EBFF] border-[1px]">
                <input
                  className="form__phone text-[15px] box-border flex-[6] relative pl-4 h-full z-0 text-black font-regular-mon rounded-r-xl rounded-l-xl"
                  placeholder="Nhập username "
                  {...register("name", {
                    required: "Không được để trống",
                    pattern: {
                      // value: /^[\D*]{1,}$/,
                      // message: "Vui lòng chỉ nhập kí tự",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-regular-mon mb-7 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0  bg-[#fff] relative z-0 border-[#98EBFF] border-[1px]">
                <input
                  className="form__phone m-[0_0_0_5px] text-[15px] box-border flex-[6] w-52 pl-2.5 h-full z-30 text-black font-regular-mon rounded-r-xl"
                  placeholder="Nhập password "
                  type="password"
                  {...register("password", {
                    required: "Không được để trống",
                    pattern: {
                      message: "Vui lòng nhập số",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
                {notify ? notify : ""}
              </div>

              {show === false ? (
                <>
                  <div
                    className={`${"corlor-text-white"} text-[12px] mt-[5px] font-regular leading-5 whitespace-pre-line flex flex-col`}
                  >
                    <div className="checkbox-tc">
                      <div
                        className="checkbox-tc"
                        style={{ marginBottom: "8px" }}
                      >
                        <div className="mt-5 flex font-light">
                          <div>
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              defaultChecked={checkAgree1}
                              onClick={(e) => handleAgree("ag1")}
                              className="checkbox-confirm-register w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                          <label
                            htmlFor="check"
                            className="text-[#333333] mr-[10px] font-[Montserrat] not-italic font-normal text-[13px] leading-[20px]"
                          >
                            {" "}
                          </label>
                          <label
                            htmlFor="check"
                            className={`${"corlor-text-white"} mr-[10px] font-regular not-italic font-normal text-[13px] leading-[20px]`}
                          >
                            Tôi đồng ý với các Điều khoản & điều kiện của chương
                            trình Xem chi tiết
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <span
                  className={`${"corlor-text-white"} text-[12px]  font-regular leading-5 whitespace-pre-line flex mt-5`}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      // padding: "5px 0 0 10px",
                      color: `${"#47CCFF"}`,
                      // "#003DA5",
                    }}
                    onClick={() => showMore()}
                  >
                    {show ? "" : "(Xem thêm)"}
                  </span>
                </span>
              )}
              {checkAgree1 ? (
                <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
                  <input
                    type="submit"
                    className="color-button-enable font-bold border-0 text-[#130682] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] leading-5"
                    value={`${"Đăng nhập"}`}
                  />
                </div>
              ) : (
                <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
                  <input
                    type="button"
                    className="color-button-disable font-bold border-0 text-[#33333] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light]  leading-5"
                    value={`${"Đăng nhập"}`}
                    onClick={handleNotify}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
      <FooterLogo />
    </>
  );
}

export default LoginQR;
